import React from "react"
import MainLayout from "../components/mainLayout"
import SEO from "../components/seo"
import Banner from "../components/banner"
import Img from "gatsby-image"
import { useStaticQuery, graphql } from "gatsby"
import home3b from '../images/home3b.jpg'
import style from "./work.module.scss"

const WorkPage = () => {
  const content = useStaticQuery(graphql`
    query {
      allMarkdownRemark(
        filter: {fileAbsolutePath: {regex: "/work/"}}
      ) {
        edges {
          node {
            frontmatter {
              title
            }
            html
          }
        }
      }
      workThrival: file(relativePath: { eq: "work_thrival.jpg" }) {
        childImageSharp {
          fluid(maxWidth: 1000) {
            ...GatsbyImageSharpFluid
          }
        }
      }
      workNorthernNights: file(relativePath: { eq: "work_northernNights.jpg" }) {
        childImageSharp {
          fluid(maxWidth: 1000) {
            ...GatsbyImageSharpFluid
          }
        }
      }
      workFarmToTable: file(relativePath: { eq: "work_farm.jpg" }) {
        childImageSharp {
          fluid(maxWidth: 1000) {
            ...GatsbyImageSharpFluid
          }
        }
      }
      workWwr: file(relativePath: { eq: "work_wwr.jpg" }) {
        childImageSharp {
          fluid(maxWidth: 1000) {
            ...GatsbyImageSharpFluid
          }
        }
      }
      workMwri: file(relativePath: { eq: "work_mwri.jpg" }) {
        childImageSharp {
          fluid(maxWidth: 1000) {
            ...GatsbyImageSharpFluid
          }
        }
      }
      workSpare: file(relativePath: { eq: "work_spare.jpg" }) {
        childImageSharp {
          fluid(maxWidth: 1000) {
            ...GatsbyImageSharpFluid
          }
        }
      }
      workBridge: file(relativePath: { eq: "work_bridge.jpg" }) {
        childImageSharp {
          fluid(maxWidth: 1000) {
            ...GatsbyImageSharpFluid
          }
        }
      }
      workPoint: file(relativePath: { eq: "work_point2.jpg" }) {
        childImageSharp {
          fluid(maxWidth: 1000) {
            ...GatsbyImageSharpFluid
          }
        }
      }
      workPga: file(relativePath: { eq: "work_pga.jpg" }) {
        childImageSharp {
          fluid(maxWidth: 1000) {
            ...GatsbyImageSharpFluid
          }
        }
      }
      workVine: file(relativePath: { eq: "work_vineRewind.jpg" }) {
        childImageSharp {
          fluid(maxWidth: 1000) {
            ...GatsbyImageSharpFluid
          }
        }
      }
    }
  `)

  function createMarkup() {
    return {__html: content.allMarkdownRemark.edges[0].node.html};
  }

  return (
    <MainLayout>
      <SEO title="Work" />
      <Banner primaryText={'Work'} backgroundImg={home3b}/>
      <div className={style.workBody}>
        <div dangerouslySetInnerHTML={createMarkup()} />
      </div>
      <div className={style.workGrid}>
        <div>
          <div>
            <h2>Thrival Music + Innovation Festival</h2>
          </div>
          <Img fluid={content.workThrival.childImageSharp.fluid} />
        </div>
        <div>
          <div>
            <h2>Women who Rock&trade;</h2>
          </div>
          <Img fluid={content.workWwr.childImageSharp.fluid} />
        </div>
        <div>
          <div>
            <h2>Northern Nights Music Festival</h2>
          </div>
          <Img fluid={content.workNorthernNights.childImageSharp.fluid} />
        </div>
        <div>
          <div>
            <h2>Spare Change TV</h2>
          </div>
          <Img fluid={content.workSpare.childImageSharp.fluid} />
        </div>
        <div>
          <div>
            <h2>Magee-Womens Research Institute</h2>
          </div>
          <Img fluid={content.workMwri.childImageSharp.fluid} />
        </div>
        <div>
          <div>
            <h2>Farm to Table Conference</h2>
          </div>
          <Img fluid={content.workFarmToTable.childImageSharp.fluid} />
        </div>
        <div>
          <div>
            <h2>The Bridge Gastropub</h2>
          </div>
          <Img fluid={content.workBridge.childImageSharp.fluid} />
        </div>
        <div>
          <div>
            <h2>Point Park University</h2>
          </div>
          <Img fluid={content.workPoint.childImageSharp.fluid} />
        </div>
        <div>
          <div>
            <h2>PGA</h2>
          </div>
          <Img fluid={content.workPga.childImageSharp.fluid} />
        </div>
        <div>
          <div>
            <h2>Vine Rewind</h2>
          </div>
          <Img fluid={content.workVine.childImageSharp.fluid} />
        </div>
      </div>
    </MainLayout>
  )
}

export default WorkPage
